// Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
// the trix-editor content (whether displayed or under editing). Feel free to incorporate this
// inclusion directly in any other asset bundle and remove this file.

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.

@import "trix/dist/trix";

/* stylelint-disable */
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      max-width: 33%;
      padding: 0 0.5em;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      max-width: 100% !important;
      padding: 0 !important;
    }
  }

  .trix ul {
    list-style-type: disc;
    padding-left: 2.5rem;
  }

  .trix ol {
    list-style-type: decimal;
    padding-left: 2.5rem;
  }
}
/* stylelint-enable */
