@import 'stylesheets/fonts';
@import 'stylesheets/vendor/flag-icon-css/sass/flag-icon';
@import '@uppy/core/dist/style';
@import 'flatpickr/dist/flatpickr';
@import 'flatpickr/dist/themes/light';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'stylesheets/actiontext';
@import 'highlight.js/scss/github';

// uppy file input + tailwindcss
.uppy-FileInput-container {
  button {
    @apply ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
}

.uppy-FileInput-input {
  display: none;
}

span.help-block {
  @apply mt-2 text-sm text-red-600;
}

.form-group.has-error {
  input {
    @apply block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md;
  }
}

span.error {
  @apply mt-2 text-sm text-red-600;
}

// notification animation
@keyframes notification-countdown {
  from {
    width: 100%;
  }

  to {
    width: 0;
  }
}

.trix-content {
  @apply text-gray-700;
}

trix-editor {
  height: auto;
  min-height: 35rem;
}

.my-nested-form > div:nth-last-of-type(2) > div > div:last-child > div:last-child > button:first-child {
  visibility: visible;
}

.my-nested-form > div:first-child > div > div:last-child > div:last-child > button:last-child {
  visibility: hidden;
}
